<template>
	<panel :show="show" @close="close">
		<template #title>
			Edit Time Block
		</template>
		<template>
			<form class="panel-form" @submit.prevent="onFormSubmit">
				<div>
					<div class="form-group">
						<label for="name" class="form-label">
							Time Block Name
						</label>
						<input
							id="name"
							v-model="form.name"
							required
							class="form-input"
							placeholder="Time Block Name"
							type="text"
						/>
					</div>
					<div class="form-group">
						<label for="start" class="form-label">
							Start time
						</label>
						<flat-pickr
							id="start"
							v-model="form.starts_at"
							required
							:config="configs.timePicker"
							type="text"
							name="start"
							class="form-input"
							placeholder="Start Time"
							@on-open="onPickerOpen"
							@on-close="onPickerClose"
						/>
					</div>
					<div class="form-group">
						<label for="end" class="form-label">
							End Time
						</label>
						<flat-pickr
							id="end"
							v-model="form.ends_at"
							required
							:config="configs.timePicker"
							type="text"
							name="end"
							class="form-input"
							placeholder="End Time"
							@on-open="onPickerOpen"
							@on-close="onPickerClose"
						/>
						<span class="text-xs text-gray-500 mt-1">
							If the end time is set earlier than the start time,
							the time block will extend to the following day.
						</span>
					</div>
					<div class="form-group">
						<label for="calendars" class="form-label">
							Select Calendars
						</label>
						<div
							v-for="calendar in calendars"
							:key="calendar.id"
							class="form-check"
						>
							<input
								:id="`calendar-${calendar.id}`"
								v-model="form.calendars"
								name="calendars"
								type="checkbox"
								:value="calendar.id"
								class="form-check-input"
							/>
							<label
								:for="`calendar-${calendar.id}`"
								class="form-check-label"
							>
								{{ calendar.name }}
							</label>
						</div>
					</div>

					<div class="form-group">
						<div class="flex-row">
							<div class="form-check">
								<input
									id="default"
									v-model="form.is_default"
									type="checkbox"
									:disabled="isReadOnly"
									class="form-check-input"
								/>
								<label for="default" class="form-check-label">
									Default time block for calendar(s)
								</label>
							</div>
							<div
								v-if="timeBlock && timeBlock.is_default"
								class="text-xs text-gray-500 mt-1"
							>
								To remove the default option for this time
								block, set another time block as default.
							</div>
						</div>
					</div>

					<hr class="my-4" />

					<div class="form-group">
						<label for="days" class="form-label">
							Select Days
						</label>
						<div
							v-for="(day, index) in weekDays"
							:key="day"
							class="form-check"
						>
							<input
								:id="`week-day-${day}`"
								v-model="form.days_of_the_week"
								type="checkbox"
								:value="index"
								class="form-check-input"
							/>
							<label
								:for="`week-day-${day}`"
								class="form-check-label"
								v-text="day"
							/>
						</div>
					</div>
					<div class="form-group">
						<div
							v-if="errors.length"
							class="bg-red-100 border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md"
							role="alert"
						>
							<div class="flex">
								<div class="py-1">
									<svg
										class="fill-current h-6 w-6 text-red-500 mr-4"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
									>
										<path
											d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
										/>
									</svg>
								</div>
								<div>
									<p class="font-bold">Error</p>
									<p
										v-for="error in errors"
										:key="error"
										class="text-sm"
									>
										{{ error }}
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="flex justify-between mt-4">
						<button
							type="button"
							class="btn btn-danger w-50 mr-2"
							@click.prevent="close"
						>
							<font-awesome-icon
								class="btn-icon"
								:icon="['far', 'times-octagon']"
							/>
							<span class="btn-label">Cancel</span>
						</button>
						<button
							ref="last"
							type="submit"
							class="btn btn-outline-success w-50 ml-2"
							@keydown.tab="onLastElementTab"
						>
							<font-awesome-icon
								class="btn-icon"
								:icon="['far', 'save']"
								aria-hidden="true"
							/>
							<span class="btn-label">Save</span>
						</button>
					</div>
				</div>
			</form>
		</template>
	</panel>
</template>
<script>
import Panel from './Panel'
import 'flatpickr/dist/flatpickr.css'
import PanelMixin from '@/mixins/PanelMixin'
import { mapActions, mapGetters } from 'vuex'
import FlatPickr from 'vue-flatpickr-component'

/**
 * The available week days.
 *
 * @type {Array}
 */
const DAYS = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
]

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		FlatPickr,
		Panel,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Determine if the default input must be read only.
		 *
		 * @return {Boolean}
		 */
		isReadOnly() {
			if (!this.timeBlock) {
				return
			}

			return this.timeBlock.is_default
		},

		...mapGetters({
			calendars: 'calendars',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Get the errors from response.
		 *
		 * @param {ErrorResponse} response
		 * @return {Array}
		 */
		getErrors(response) {
			if (!response.hasErrors()) {
				return []
			}

			const errors = response.errors()

			return Object.keys(errors).map(key => errors[key][0])
		},

		/**
		 * Fill the form with the event's information.
		 *
		 * @return {void}
		 */
		fillForm() {
			const {
				id,
				name,
				starts_at,
				ends_at,
				days_of_the_week,
				is_default,
				partner_id,
				calendars,
			} = this.timeBlock

			this.form.id = id
			this.form.ends_at = ends_at
			this.form.name = name
			this.form.starts_at = starts_at
			this.form.is_default = is_default
			this.form.partner_id = partner_id
			this.form.days_of_the_week = days_of_the_week
			this.form.calendars = calendars.map(calendar => calendar.id)
		},

		/**
		 * Handle an error API response.
		 *
		 * @param {ErrorResponse} response
		 * @return {void}
		 */
		onErrorResponse(response) {
			this.$alert.error(response.message())
		},

		/**
		 * Handle the form submit event.
		 *
		 * @return {void}
		 */
		async onFormSubmit() {
			this.errors = []

			try {
				if (this.form.ends_at.split(':').length !== 3) {
					this.form.ends_at = `${this.form.ends_at}:00`
				}

				if (this.form.starts_at.split(':').length !== 3) {
					this.form.starts_at = `${this.form.starts_at}:00`
				}

				await this.update(this.form)

				this.$alert.success('timeBlocks.update.success')
			} catch (e) {
				this.onErrorResponse(e)

				return (this.errors = this.getErrors(e))
			}

			this.resetForm()

			this.close()
		},

		/**
		 * Listen for the FlatPickr close event.
		 *
		 * @return {void}
		 */
		onPickerClose() {
			this.openPicker = null
		},

		/**
		 * Listen for the FlatPickr open event.
		 *
		 * The open event is delayed to prevent overlapping
		 * of the open, blur, close events when using the keyboard.
		 *
		 * @param {Array} datesSelected
		 * @param {String} dateStr
		 * @param {FlatPickr} instance
		 * @return {void}
		 */
		onPickerOpen(datesSelected, dateStr, instance) {
			if (
				this.openPicker &&
				instance.element !== this.openPicker.element
			) {
				this.openPicker.close()
			}

			this.openPicker = instance
		},

		/**
		 * Reset the form back to its original state.
		 *
		 * @return {void}
		 */
		resetForm() {
			this.form = {
				id: null,
				days_of_the_week: [],
				ends_at: null,
				is_default: null,
				name: null,
				partner_id: null,
				calendars: [],
				starts_at: null,
			}
		},

		...mapActions({
			update: 'timeBlocks/update',
		}),
	},

	/**
	 * The component's mergeable mixins.
	 *
	 * @type {Array}
	 */
	mixins: [PanelMixin],

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'EditTimeBlockPanel',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The time block to be edited.
		 *
		 * @type {Object}
		 */
		timeBlock: {
			type: Object,
			default: null,
			validator: prop => typeof prop === 'object',
		},
	},

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the time block object.
		 *
		 * @return {void}
		 */
		timeBlock(value, previous) {
			if (!this.timeBlock || value === previous) {
				return this.resetForm()
			}
			this.errors = []

			this.fillForm()
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			days: {
				sunday: false,
				monday: false,
				tuesday: false,
				wednesday: false,
				thursday: false,
				friday: false,
				saturday: false,
			},
			configs: {
				timePicker: {
					allowInput: true,
					enableTime: true,
					minuteIncrement: 30,
					noCalendar: true,
					wrap: true,
					time_24hr: false,
					altInput: true,
					altFormat: 'h:i K',
				},
			},
			errors: [],
			form: {
				id: null,
				calendars: [],
				days_of_the_week: [],
				ends_at: null,
				is_default: null,
				name: null,
				partner_id: null,
				starts_at: null,
			},
			weekDays: DAYS,
		}
	},
}
</script>
