<template>
	<div class="w-full">
		<div class="flex justify-end py-3">
			<button
				class="calendar-action"
				data-tooltip="Create Time Block"
				aria-label="Create"
				data-position="left"
				@click.prevent="onCreateTimeBlock"
			>
				<font-awesome-icon
					fixed-width
					:icon="['fal', 'plus']"
					aria-hidden="true"
				/>
			</button>
		</div>
		<div class="container">
			<div class="table-responsive w-100">
				<table class="table">
					<caption>
						* Default Time block
					</caption>
					<thead>
						<tr>
							<th>Name</th>
							<th>Days</th>
							<th>Time</th>
							<th>Calendars</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="block in timeBlocks"
							:key="block.id"
							class="table-row"
						>
							<td>
								{{ block.name }}
								<span v-if="block.is_default">*</span>
							</td>
							<td
								v-if="block.days_of_the_week.length === 7"
								class="text-nowrap"
							>
								Mon - Sun
							</td>
							<td v-else>
								{{
									block.days_of_the_week
										.map(d => weekDays[d])
										.join(', ')
								}}
							</td>
							<td>
								{{
									block.starts_at
										| toTimeblockTime('hh:mm A', 'HH:mm:ss')
								}}
								-
								{{
									block.ends_at
										| toTimeblockTime('hh:mm A', 'HH:mm:ss')
								}}
							</td>
							<td>
								{{ block.calendars | toNameString }}
							</td>
							<td class="text-nowrap overflow-visible">
								<button
									class="calendar-action"
									data-tooltip="Edit"
									aria-label="Edit"
									@click.prevent="onEditTimeBlock(block)"
								>
									<font-awesome-icon
										fixed-width
										:icon="['fal', 'edit']"
										aria-hidden="true"
									/>
								</button>
								<button
									class="calendar-action"
									data-tooltip="Delete"
									aria-label="Delete"
									@click.prevent="onDeleteTimeBlock(block.id)"
								>
									<font-awesome-icon
										fixed-width
										:icon="['fal', 'trash']"
										aria-hidden="true"
									/>
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<create-time-block-panel
			:show="panels.create"
			@close="panels.create = false"
		/>

		<delete-time-block-panel
			:id="id"
			:show="panels.delete"
			@close="panels.delete = false"
		/>

		<edit-time-block-panel
			:id="id"
			:show="panels.edit"
			:time-block="timeBlock"
			@close="panels.edit = false"
		/>
	</div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import EditTimeBlockPanel from '@/components/Panels/EditTimeBlockPanel'
import CreateTimeBlockPanel from '@/components/Panels/CreateTimeBlockPanel'
import DeleteTimeBlockPanel from '@/components/Panels/DeleteTimeBlockPanel'

/**
 * The available week days.
 *
 * @type {Array}
 */
const DAYS = ['Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa']

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		CreateTimeBlockPanel,
		DeleteTimeBlockPanel,
		EditTimeBlockPanel,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		...mapGetters({
			timeBlocks: 'timeBlocks',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	filters: {
		/**
		 * Convert an array of calendars to a comma separated string.
		 *
		 * @param {Array} calendars
		 * @return {String}
		 */
		toNameString(calendars) {
			return calendars.map(calendar => calendar.name).join(', ')
		},

		/**
		 * Convert a timestamp to AM/PM format.
		 *
		 * @return {String}
		 */
		toTimeblockTime(timestamp) {
			return moment(timestamp, 'HH:mm:ss').format('hh:mm A')
		},
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Handle the on create time block click event.
		 *
		 * @return {void}
		 */
		onCreateTimeBlock() {
			this.panels.create = true
		},

		/**
		 * Handle the on delete time block click event.
		 *
		 * @param {Number} id
		 * @return {void}
		 */
		onDeleteTimeBlock(id) {
			this.id = id

			this.panels.delete = true
		},

		/**
		 * Handle the on edit time block click event.
		 *
		 * @param {Object} timeBlock
		 * @return {void}
		 */
		onEditTimeBlock(timeBlock) {
			this.timeBlock = timeBlock

			this.panels.edit = true
		},

		...mapActions({
			fetchCalendars: 'calendars/get',
			fetchTimeBlocks: 'timeBlocks/get',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'TimeBlocks',

	/**
	 * The component's created lifecycle hook.
	 *
	 * @return {void}
	 */
	async created() {
		try {
			await [this.fetchTimeBlocks(), this.fetchCalendars()]
		} catch (e) {
			this.$alert.error('default.error')
		}
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			id: null,
			panels: {
				create: false,
				delete: false,
				edit: false,
			},
			timeBlock: null,
			weekDays: DAYS,
		}
	},
}
</script>
