<template>
	<panel :show="show" @close="close">
		<template #title>
			Delete Time Block
		</template>
		<template>
			<form class="panel-form" @submit.prevent="onFormSubmit">
				<img
					src="@/assets/img/undraw-throw-away.svg"
					class="panel-illustration"
				/>
				<div>
					<div class="form-group">
						<span>
							Are you sure you want to delete this time block?
						</span>
					</div>
					<div class="flex justify-between">
						<button
							type="button"
							class="btn btn-danger w-50 mr-2"
							@click.prevent="close"
						>
							<font-awesome-icon
								class="btn-icon"
								:icon="['far', 'times-octagon']"
							/>
							<span class="btn-label">Cancel</span>
						</button>
						<button
							ref="last"
							type="submit"
							class="btn btn-outline-success w-50 ml-2"
							@keydown.tab="onLastElementTab"
						>
							<font-awesome-icon
								class="btn-icon"
								:icon="['far', 'save']"
								aria-hidden="true"
							/>
							<span class="btn-label">Delete</span>
						</button>
					</div>
				</div>
			</form>
		</template>
	</panel>
</template>
<script>
import Panel from './Panel'
import { mapActions } from 'vuex'
import PanelMixin from '@/mixins/PanelMixin'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		Panel,
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Handle an error API response.
		 *
		 * @param {ErrorResponse} response
		 * @return {void}
		 */
		onErrorResponse(response) {
			this.$alert.error(response.message())
		},

		/**
		 * Handle the form submit event.
		 *
		 * @return {void}
		 */
		async onFormSubmit() {
			try {
				await this.delete(this.id)

				this.$alert.success('timeBlocks.delete.success')
			} catch (e) {
				this.onErrorResponse(e)
			}

			this.close()
		},

		...mapActions({
			delete: 'timeBlocks/delete',
		}),
	},

	/**
	 * The component's mergeable mixins.
	 *
	 * @type {Array}
	 */
	mixins: [PanelMixin],

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'DeleteTimeBlockPanel',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The time block's id to be edited.
		 *
		 * @type {String}
		 */
		id: {
			required: true,
			validator: prop => typeof prop === 'number' || prop === null,
		},
	},
}
</script>
